import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Img from "gatsby-image";

import SEO from "../components/seo";
import "./../components/layout.css";

import PropTypes from "prop-types";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

const theme = {
  blue: "#0632D3",
  grey: "#494949",
};

const GlobalStyle = createGlobalStyle`
  a {
    color: #0632D3;
    font-weight: bold;
    text-decoration: none;
  }
  html {
    background-color: #dce6e6;
  }
  p{
    /* color: ${(props) => (props.colouredBackground ? "white" : "#0632D3")}; */
    font-size: 0.95em;
  }
  h1 {
    font-size: 2em;
  }
  a{
    background-color: #dce6e6 !important;
  }
`;

const Layout = ({ children, fullWidth, hideNav = false }) => {
  return (
    <main>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        {!hideNav && <Header reverse={false} />}
        <Wrapper fullWidth={fullWidth}>{children}</Wrapper>
      </ThemeProvider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Wrapper = styled.div`
  margin: 0 auto;
  ${({ fullWidth }) =>
    !fullWidth &&
    `max-width: 960px; padding: 1em; margin-bottom: 2em;
    margin: 2em auto;
  }`};
  .animations-page {
    max-width: 600px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 800px) {
    ${({ fullWidth }) =>
      !fullWidth &&
      `padding: 80px 1em 2em 1em;;
  }`};
  }
`;

const TestPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bumperLogo: file(relativePath: { eq: "bumper.png" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      poster: file(relativePath: { eq: "Bumper-A2-poster.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mockup: file(relativePath: { eq: "Bumper-A2-poster_mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      gif: file(relativePath: { eq: "Bumper-comp.gif" }) {
        publicURL
      }
    }
  `);

  return (
    <Layout>
      <BumperWrapper>
        <SEO title="Bumper" />
        <Logo fluid={data.bumperLogo.childImageSharp.fluid} />
        <StyledImg fluid={data.poster.childImageSharp.fluid} />
        <StyledImg fluid={data.mockup.childImageSharp.fluid} />
        <StyledGif src={data.gif.publicURL} />
      </BumperWrapper>
    </Layout>
  );
};

export default TestPage;

const BumperWrapper = styled.div`
  padding-top: 4em;
  color: white;
  max-width: 700px;
  margin: 0 auto;

  html {
    background-color: read-only;
  }
  h1 {
    font-weight: bold;
    text-align: center;
    font-size: 2em;
  }
  p > a {
    color: white;
  }
`;

const Logo = styled(Img)`
  max-width: 50%;
  margin: 0 auto;
  margin-bottom: 2em;
`;

const StyledImg = styled(Img)`
  margin-bottom: 1.5em;
`;

const StyledGif = styled.img`
  width: 100%;
`;
